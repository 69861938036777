.body,html  {
    background-image: url("./components/Hero.jpg");
    background-size:cover;
    background-attachment:fixed;
    overflow-y: visible;
    height: 100vh;
    width: 100vw;
    font-family: 'Barlow Condensed', 'Lato', sans-serif;
    
}


.ProfileCard {
    position: absolute;
    margin-top: 5%;
   padding-bottom: 5% !important;
    left: 4%;
    width: 90%;
    border-radius: 50px;
    background: #e0e0e0;
    box-shadow: -5px -5px 7px #636363,
        5px 5px 7px #CBD94A;

}

.mobile-ProfileCard {
    display: none;
}

.myTab {
    left: 79% !important;
    text-transform: none !important;
    color: #010A26;
    margin-bottom: 0%;
    font-size: 1.1em !important;
    font-family: Barlow Condensed, sans-serif !important;
    font-weight: 700 !important;
    border-bottom: 3px solid #CBD94A !important;
}

.myTab:hover,
.myTab:focus {
    color: #010A26;
    border-bottom-left-radius: 40px;
    border-bottom: 5px #CBD94A solid;
    box-shadow: -5px -5px 7px transparent,
        5px 7px -1px #CBD94A;
    text-decoration: none;
}

.myTab.Mui-selected {
    color: #010A26 !important;
}

.myIndicator {
    height: 0px !important;
}

.grid-container {
    display: grid;
    grid-template-columns: 3fr 9fr;
    grid-gap: 0;
    align-items: start;
    padding-top: 10px;

}


.ProfileCard {
    display: flex;
    align-items: flex-start;
    position: relative;
    gap: 10px;

}

.Profile-img {
    border-radius: 70%;
    width: 190px;
    height: 210px;
}

.ProfileCard::after {
    content: '';
    position: absolute;
    margin-left: 22%;
    top: 8%;
    height: 80%;
    /* Proportional height */
    width: 3px;
    background: #CBD94A;
}

.ProfileCard-title {
    color: #010A26;
    font-size: 2.8em;
    font-weight: 700;
    text-align: center;
    font-family: Barlow Condensed, sans-serif;
    margin-top: 0%;
    margin-bottom: 0%;
}

/* Abstract section */
.Profileabstract-one {
    margin-left: 1%;
    font-size: 0.9em;
    text-align: justify;
    font-weight: 400;
    line-height: 1.5em;
    font-family: Lato, sans-serif;
    margin-right: 5%;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 1.1em;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 0%;
    margin-left: 5%;
}

.social-links a {
    color: #010A26;
    transition: .4s ease-in-out;
}

.social-links a:hover {
    color: #CBD94A;
}

.social-links button {
    padding: 0.8em 1.7em;
    display: block;
    margin: auto;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    background: #CBD94A;
    color: #010A26;
    transition: .4s ease-in-out;
}

.social-links button:hover {
    background: #010A26;
    color: #CBD94A;
    cursor: pointer;
}

.Profileabstract-two {
    margin-left: 1%;
    margin-top: 0%;
    font-size: 0.9em;
    text-align: justify;
    font-weight: 400;
    line-height: 1.5em;
    font-family: Lato, sans-serif;
    margin-right: 5%;
}

.highlighted-quote {
    font-size: 1em;
    background-color: #d3d3d3;
    border-radius: 13px;
    padding: 20px;
    margin-top: 15px;
    margin-right: 25px;

}

@media (min-width: 768px) {
    .quote-two {
        display: none;
    }
}

/*TODO: Fix the tablet view*/
@media screen and (max-width: 768px) {
    .ProfileCard {
        width: 80%;
        /* Adjust as needed */
        left: 10%;
        /* Center the card */
        top: 10%;
        /* Adjust as needed */
    }


    .myTab {
        left: 67% !important;
        text-transform: none !important;
        color: #010A26;
        margin-bottom: 0%;
        font-family: Barlow Condensed, sans-serif !important;
        font-weight: 700 !important;
        border-bottom: 3px solid #CBD94A !important;
    }

    .highlighted-quote {
        background-color: #d3d3d3;
        border-radius: 13px;
        padding: 8px;
        margin: 15px;
        margin-left: 25px;
        margin-right: 25px;
        line-height: 1em;
    }

    /*This is the green vertical divider*/
    .ProfileCard::after {
        content: '';
        position: absolute;
        left: 65%;
        top: 4%;
        height: 50%;
        width: 3px;
        background: #CBD94A;
    }

}

/* For mobile phones: */
@media screen and (max-width: 480px) {
    .grid-container {
        flex-wrap: wrap;
    }

    .mobile-ProfileCard {
        display: block;
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        border-radius: 50px;
        background: #e0e0e0;
        box-shadow: -5px -5px 7px #636363,
            5px 5px 7px #CBD94A;
    }

    .ProfileCard {
        display: none;
    }

    .mobile-myTab {
        text-transform: none !important;
        color: #010A26;
        margin-bottom: 0%;
        font-size: 1.1em !important;
        font-family: Barlow Condensed, sans-serif !important;
        font-weight: 700 !important;
        border-bottom: 3px solid #CBD94A !important;
    }

    .mobile-myTab:hover,
    .mobile-myTab:focus {
        color: #010A26;
        border-bottom-left-radius: 40px;
        border-bottom: 5px #CBD94A solid;
        box-shadow: -5px -5px 7px transparent,
            5px 7px -1px #CBD94A;
        text-decoration: none;
    }

    .mobile-myTab.Mui-selected {
        color: #010A26 !important;
    }

    .ProfileCard-title {
        color: #010A26;
        font-size: 2.2em;
        font-weight: 700;
        text-align: right;
        font-family: Barlow Condensed, sans-serif;
        margin-top: 10%;
        margin-left: 45px;
        margin-right: 0%;
        margin-bottom: 0%;
    }

    .social-links {
        display: flex;
        justify-content: center;
        gap: 1.1em;
        margin-top: 12%;
        margin-left: 45% !important;
        border-bottom: 3px #CBD94A solid;
        width: 63%;
    }

    .Profile-img {
        border-radius: 70%;
        width: 120px;
        height: 140px;
        margin-left: 40px;
    }

    .Profileabstract-one {
        font-size: 0.8em;
        text-align: justify;
        font-weight: 400;
        line-height: 1.5em;
        font-family: Lato, sans-serif;
        margin-left: 25px;
        margin-right: 25px;
    }

}
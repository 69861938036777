:root {
  --primary-color: #010A26;
  --secondary-color: #CBD94A;
  --background-color: #e0e0e0;
  --shadow-dark: -5px -5px 7px #636363;
  --shadow-light: 5px 5px 7px #CBD94A;
  --font-family-primary: 'Barlow Condensed', sans-serif;
  --font-family-secondary: 'Lato', sans-serif;
  --gap: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-secondary);
  background-size: cover;
  background-position: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: var(--gap);
  align-items: center;
  justify-content: center;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}


.card, .mobile-card {
  position: relative;
  margin: auto;
  background: var(--background-color);
  box-shadow: var(--shadow-dark), var(--shadow-light);
}

.card {
  width: 600px;
  height: 400px;
  border-radius: 50px;
  padding-left: 30px;
}

.mobile-card {
  display: none;
  width: 320px;
  border-radius: 30px;
  padding-left: 30px;
  padding-bottom: 20px;
}

/*This is the green vertical divider*/
.card::after, .mobile-card::after {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  background: var(--secondary-color);
}

.card::after {
  left: 33%;
  top: 20%;
  height: 58%;
  width: 3px;
}

.mobile-card::after {
  left: 29%;
  top: 22%;
  height: 3px;
  width: 50%;
}

.cardTitle {
  color: var(--primary-color);
  font-size: 3em;
  font-weight: 700;
  text-align: center;
  font-family: var(--font-family-primary);
  margin-top: 25%;
}

.abstract, .abstract-mobile {
  font-size: 0.8em;
  text-align: left;
  font-weight: 400;
  line-height: 1.5em;
  font-family: var(--font-family-secondary);
}

.abstract {
  display: block;
  margin: 0% 3% 0 7%;
}

.abstract-mobile {
  display: none;
  margin: -3% 0 10%;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.1em;
  margin: 0% auto;
}

.social-links a,
.mobile-links a {
  color: var(--primary-color);
  transition: 0.4s ease-in-out;
}

.social-links a:hover,
.mobile-links a:hover {
  color: var(--secondary-color);
}

.button, .social-links button {
  padding: 0.8em 1.7em;
  border-radius: 25px;
  font-weight: bold;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  background: var(--secondary-color);
  color: var(--primary-color);
  border: none;
}


.social-links button:hover {
  background: var(--primary-color);
  color: var(--secondary-color);
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button {
  position: relative;
  overflow: hidden;
  height: 2rem;
  padding: 0 0.5rem;
  margin: 0 1.5rem;
  border-radius: 1.5rem;
  background: #e0e0e0;
  background-size: 400%;
  border: none;
  transition: all 0.5s ease;
  width: 90px;
  font-size: 1em;
  font-family: Barlow Condensed, sans-serif;
  font-weight: 700;
  margin-top: 5%;
}

.button a {
  color: #010A26;
  text-decoration: none;
}

.button.shift {
  left: 0;
  top: 0
}

.button:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleY(0);
  transform-origin: 50% 0%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(180deg,
      rgba(180, 198, 42, 1) 100%,
      rgba(224, 224, 224, 1) 30%);
  transition: all 0.475s;
}


.footer {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 0.9em;
  width: 100%; /* Full-width footer */
  position: fixed;
  bottom: 0%;
}



@media screen and (max-width: 768px) {
  .card {
    width: 80%;
    max-width: 600px;
    height: auto;
    border-radius: 20px;
  }

  .card-container {
    height: 90vh;
    padding: 5%;
  }

  .cardTitle {
    font-size: 2.5em;
    margin-top: 15%;
  }
}

@media screen and (max-width: 480px) {
  .card-container {
    display: none;
  }

  .mobile-card-container {
    display: flex;
  }

  .mobile-links {
    display: flex;
    justify-content: right;
    gap: 0.5em;
    margin-top: 100px;
  }

  .mobile-card {
    display: block;
    margin: 10% auto;
  }
}


/* For mobile phones: */
@media screen and (max-width: 480px) {
  .cardTitle {
    text-align: left;
  }

  .abstract {
    display: none;
  }

  .abstract-mobile {
    display: block;
  }

  .social-links {
    display: none;
  }

  .mobile-links {
    display: flex;
    justify-content: right;
    gap: 0.5em;
    margin-top: 100px;
  
  }

  .mobile-links a {
    color: #010A26;
    transition: .4s ease-in-out;
  }

  .mobile-links a:hover {
    color: #CBD94A;
  }

  .mobile-links button {
    padding: .8em 1.7em;
    display: block;
    margin: auto;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    background: #CBD94A;
    color: #010A26;
    transition: .4s ease-in-out;
    margin-bottom: 10%;
  }

  .mobile-links button:hover {
    background: #010A26;
    color: #CBD94A;
    cursor: pointer;
  }

  .left-column {
    display: flex;
    justify-content: space-between;
    column-gap: 5rem;
  }

  .button {
    display: flex;
    margin: auto;
    overflow: hidden;
    height: 2rem;
    padding: 0.2rem 0.5rem;
    border-radius: 1.5rem;
    background: #e0e0e0;
    background-size: 400%;
    border: none;
    transition: all 0.5s ease;
    width: 90px;
    font-size: 1em;
    font-family: Barlow Condensed, sans-serif;
    font-weight: 700;
    justify-content: center;
  }

  .button a {
    color: #010A26;
    text-decoration: none;
  }

  .button.shift {
    left: 0;
    top: 0
  }

  .button:hover::before {
    transform: scaleX(1);
  }

  .button-content {
    position: relative;
    z-index: 1;
  }

  .button::before {
    content: '';
    position: absolute;
    top: 0;
    transform: scaleY(0);
    transform-origin: 50% 0%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(180deg,
        rgba(180, 198, 42, 1) 100%,
        rgba(224, 224, 224, 1) 30%);
    transition: all 0.475s;
  }
}